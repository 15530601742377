* {
  margin: 0;
  padding: 0;
  font-family: Inter-Regular, Inter !important;
}

body {
  overflow-x: hidden;
  /*background: #D6DCED;*/
}

div.bm-menu,
div.bg-white {
  height: auto !important;
  min-height: 100vh;
}
.about {
  position: absolute;
  z-index: 9 !important;
  left: 50%;
  bottom: 5.208vw;
  transform: translateX(-50%);
}

@media screen and (max-width: 769px) {
  .about {
    left: 0 !important;
    span {
      overflow: height;
      white-space: nowrap;
    }
  }
}
@media screen and (max-width: 1536px) {
  .about {
    bottom: 3.808vw;
  }
}
